/*=========================================================================================
  File Name: moduleDoctorSessionActions.js
  Description: DoctorSession Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  AddDoctorSession({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/DoctorSession/AddDoctorSession", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.Data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetAllDoctorSession({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/DoctorSession/GetAllDoctorSessions")
        .then(response => {
          commit("SET_DoctorSession", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchDoctorSession({ commit }, search) {
    return new Promise((resolve, reject) => {
      axios
        .post("/API/DoctorSession/SearchDoctorSessions", search)
        .then(response => {
          commit("SET_DoctorSession", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UpdateDoctorSession({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/DoctorSession/UpdateDoctorSession?ID=" + item.ID, item)
        .then(response => {
          commit("UPDATE_DoctorSession", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetDoctorSession({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/DoctorSession/GetDoctorSession", itemid)
        .then(response => {
          commit("UPDATE_DoctorSession", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteDoctorSession({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/DoctorSession/DeleteDoctorSession?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
