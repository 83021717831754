/*=========================================================================================
  File Name: moduleDoctorSessionMutations.js
  Description: DoctorSession Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.DoctorSessions.unshift(item)
  },
  SET_DoctorSession(state, DoctorSessions) {
    state.DoctorSessions = DoctorSessions
  },
  UPDATE_DoctorSession(state, DoctorSession) {
    const DoctorSessionIndex = state.DoctorSessions.findIndex((p) => p.ID == DoctorSession.ID)
    Object.assign(state.DoctorSessions[DoctorSessionIndex], DoctorSession)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.DoctorSessions.findIndex((p) => p.ID == itemId)
    state.DoctorSessions.splice(ItemIndex, 1)
},
}
